export default [
  // {
  //   name: 'sheetjsdemo',
  //   path: '/sheetjsdemo',
  //   component: './sheetjsdemo',
  //   // hideInMenu :true
  // },
  {
    name: 'login',
    path: '/login',
    component: './operation/Login',
    hideInMenu: true
  },

  {
    name: 'print',
    path: '/print',
    component: '../layouts/operation/PrintLayout',
    hideInMenu: true,
    routes: [
      {
        name: 'print',
        path: '/print/deliver',
        component: './operation/deliver/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/prod-order',
        component: './operation/order/prodOrder/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/excess-stock',
        component: './operation/order/program/Detail/SteelPlate/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/steel-plate',
        component: './operation/materialLibrary/materialScience/steelPlate/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/steel-plates',
        component: './operation/materialLibrary/materialScience/steelPlate/List/Prints',
        hideInMenu: true,
      },
      {//中心仓打印
        name: 'print',
        path: '/print/steel-plate-centre',
        component: './operation/centreLibrary/centreScience/steelPlate/Print',
        hideInMenu: true,
      },
      {//中心仓打印
        name: 'print',
        path: '/print/steel-plates-centre',
        component: './operation/centreLibrary/centreScience/steelPlate/List/Prints',
        hideInMenu: true,
      },
      {//中心仓打印
        name: 'print',
        path: '/print/centre-surplus',
        component: './operation/centreLibrary/centreSurplus/steelPlate/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/material-surplus',
        component: './operation/materialLibrary/materialSurplus/steelPlate/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/order-print',
        component: './operation/order/salesOrder/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/prod-plan',
        component: './operation/order/prodPlan/Print',
        hideInMenu: true,
      },
      {
        name: 'print',
        path: '/print/contract-print',
        component: './operation/order/salesOrder/Contract',
        hideInMenu: true,
      },
  //索赔单打印
      {
        name: 'print',
        path: '/print/claim',
        component: './operation/postSale/claimManagement/print',
        hideInMenu: true,
      },
      //售后单打印
      {
        name: 'print',
        path: '/print/afterSaleOrder',
        component: './operation/postSale/afterSaleList/print',
        hideInMenu: true,
      },

    ]
  },
  {
    path: '/',
    component: '../layouts/operation/OperationLayout',
    authority: ['ROLE_OPERATION'],
    routes: [
      {
        name: 'home',
        icon: 'home',
        path: '/',
        component: './operation/Overview',
      },
      {
        name: 'pdfview',
        path: '/pdfview',
        icon: 'home',
        component: './operation/PdfView',
      },
      {
        name: 'inquiry',
        icon: 'solution',
        path: '/inquiry',
        routes: [
          {
            path: '/inquiry',
            redirect: '/inquiry/first',
          },
          {
            name: 'first',
            icon: 'team',
            path: '/inquiry/first',
            component: './operation/inquiry/First',
          },
          {
            name: 'list',
            path: '/inquiry/list',
            icon: 'profile',
            component: './operation/inquiry/List',
          },
          {
            name: 'change',
            path: '/inquiry/change',
            icon: 'profile',
            hideInMenu: true,
            showBack:true,
            component: './operation/inquiry/Change',
          },
          {
            name: 'detail',
            path: '/inquiry/detail',
            component: './operation/inquiry/Detail',
            hideInMenu: true,
            showBack:true,
          },
        ]
      },
      {
        name: 'order',
        icon: 'cluster',
        path: '/order',
        routes: [
          {
            path: '/order',
            redirect: '/order/sale-order',
          },
          {
            name: 'sale-order',
            icon: 'shopping',
            path: '/order/sale-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/sale-order',
                redirect: '/order/sale-order/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/sale-order/list',
                component: './operation/order/salesOrder/OrderList',
              },
              {
                name: 'detail',
                path: '/order/sale-order/detail',
                component: './operation/order/salesOrder/OrderDetail',
                showBack:true,
              },
              {
                name: 'applyChange',
                path: '/order/sale-order/applyChange',
                component: './operation/order/ChangeOrder/ApplyChange',
                hideInMenu:true
              },
              {
                name: 'partChangeTable',
                path: '/order/sale-order/partChangeTable',
                component: './operation/order/ChangeOrder/ApplyChange/PartChangeTable/HandsontableExcel',
                hideInMenu:true,
                hideInNavTabs:true
              },
              {
                name: 'partChangeSubmit',
                path: '/order/sale-order/partChangeSubmit',
                component: './operation/order/ChangeOrder/ApplyChange/ChangePartSubmit',
                hideInMenu:true,
                hideInNavTabs:true
              },
              {
                name: 'allocation',
                path: '/order/sale-order/allocation',
                component: './operation/order/prodPlan/Form',
                showBack:true,
              },

              {
                name: 'examine-deliver',
                path: '/order/sale-order/examine-deliver',
                // component: './operation/order/salesOrder/ExamineDeliver',
              },
              {//批量收款
                name: 'batch-collection',
                path: '/order/sale-order/batch-collection',
                component: './operation/order/salesOrder/BatchCollectionForm',
                showBack:true,
              },
            ]
          },
          {
            name: 'changeOrder',
            icon: 'home',
            path: '/order/changeOrder',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/changeOrder',
                redirect: '/order/changeOrder/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/changeOrder/list',
                component: './operation/order/ChangeOrder',
              },
              {
                name: 'approvalInfo',
                path: '/order/changeOrder/approvalInfo',
                component: './operation/order/ChangeOrder/ApprovalChange',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'offerInfo',
                path: '/order/changeOrder/offerInfo',
                component: './operation/order/ChangeOrder/OfferChange',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'changeContent',
                path: '/order/changeOrder/changeContent',
                component: './operation/order/ChangeOrder/OrderChangeContent',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'distributionInfo',
                path: '/order/changeOrder/distributionInfo',
                component: './operation/order/ChangeOrder/DistributionChange',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'factoryChangeIndex',
                path: '/order/changeOrder/factoryChangeIndex',
                component: './operation/order/ChangeOrder/FactoryChange',
                hideInMenu: true,
                showBack:true,
              },
            ]
          },
          {
            name: 'prod-plan',
            icon: 'apartment',
            path: '/order/prod-plan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/prod-plan',
                redirect: '/order/prod-plan/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/prod-plan/list',
                component: './operation/order/prodPlan/List',
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/prod-plan/detail',
                component: './operation/order/prodPlan/Detail',
                showBack:true,
              },
              {
                name: 'refuseDetail',
                icon: 'home',
                path: '/order/prod-plan/refuseDetail',
                component: './operation/order/prodPlan/refuseDetail',
                showBack:true,
              },

            ]
          },
          {
            name: 'task',
            icon: 'read',
            path: '/order/task',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/task',
                redirect: '/order/task/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/task/list',
                component: './operation/order/task/List',
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/task/detail',
                component: './operation/order/task/Detail',
                showBack:true,
              },
            ]
          },
          {
            name: 'program',
            icon: 'laptop',
            path: '/order/program',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/program',
                redirect: '/order/program/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/program/list',
                component: './operation/order/program/List',
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/program/detail',
                component: './operation/order/program/Detail',
                showBack:true,
              },
              {
                name: 'erpDetail',
                icon: 'home',
                path: '/order/program/erpDetail',
                component: './operation/order/program/erpLogDetail',
                showBack:true,
              },
              {
                name: 'editProgramCuttingTimes',
                icon: 'home',
                path: '/order/program/editProgramCuttingTimes',
                component: './operation/order/program/editProgramCuttingTimes',
              },
            ]
          },

          {
            name: 'prod-order',
            icon: 'printer',
            path: '/order/prod-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/prod-order',
                redirect: '/order/prod-order/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/prod-order/list',
                component: './operation/order/prodOrder/List',
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/prod-order/detail',
                component: './operation/order/prodOrder/Detail',
                showBack:true,
              },
            ]
          },
          {//后工序委外订单
            name: 'procedure-order',
            icon: 'printer',
            path: '/order/procedure-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/procedure-order',
                redirect: '/order/procedure-order/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/procedure-order/list',
                component: './operation/order/procedureOrder/List',
              },
            ]
          },
          {//手动新增进度款订单
            name: 'payment-order',
            icon: 'printer',
            path: '/order/payment-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/payment-order',
                redirect: '/order/payment-order/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/payment-order/list',
                component: './operation/order/paymentOrder/OrderList',
              },
              {
                name: 'detail',
                icon: 'home',
                path: '/order/payment-order/detail',
                component: './operation/order/paymentOrder/OrderDetail',
                showBack:true,
              },
            ]
          },
          //手动新增客户跟踪订单
          {
            name: 'customer-order',
            icon: 'shopping',
            path: '/order/customer-order',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/customer-order',
                redirect: '/order/customer-order/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/customer-order/list',
                component: './operation/order/customerOrder/OrderList',
              },
            ]
          },
          {
            name: 'order-track',
            icon: 'select',
            path: '/order/order-track',
            hideChildrenInMenu:true,
            routes: [
              {
                path: '/order/order-track',
                redirect: '/order/order-track/list',
              },
              {
                name: 'list',
                path: '/order/order-track/list',
                icon: 'select',
                component: './operation/order/orderTrack/list',
              },
            ]
          },
          //零件材质变更报表
          {
            name: 'materialChange',
            icon: 'select',
            path: '/order/materialChange',
            hideChildrenInMenu:true,
            routes: [
              {
                path: '/order/materialChange',
                redirect: '/order/materialChange/list',
              },
              {
                name: 'list',
                path: '/order/materialChange/list',
                icon: 'select',
                component: './operation/order/ChangeOrder/MaterialChangeReport',
              },
            ]
          },
          //澳克送货单推送
          {
            name: 'prod-ankePlan',
            icon: 'apartment',
            path: '/order/prod-ankePlan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/order/prod-ankePlan',
                redirect: '/order/prod-ankePlan/list',
              },
              {
                name: 'list',
                icon: 'home',
                path: '/order/prod-ankePlan/list',
                component: './operation/order/prodAnkePlan/List',
              },
            ]
          },
          {
            name: 'prod-plan-detail',
            icon: 'apartment',
            path: '/order/prod-plan-detail',
            component: './operation/order/prodPlanDetail'
          },
          {
            name: 'sales-order-detail',
            icon: 'apartment',
            path: '/order/sales-order-detail',
            component: './operation/order/salesOrderDetail'
          },
        ],
      },
      //后工序管理
      {
        name: 'workProcess',
        icon: 'solution',
        path: '/workProcess',
        routes: [
          {//后工序分配
            name: 'partsList',
            icon: 'home',
            path: '/workProcess/parts-order/partsList',
            component: './operation/workProcess/partsProcess/Form',
          },
          {
            path: '/workProcess',
            redirect: '/workProcess/list',
          },
          {
            name: 'list',
            path: '/workProcess/workProcess-order/list',
            icon: 'profile',
            component: './operation/workProcess/workProcessOrder',
          },
          {//后工序发票管理
            name: 'invoiceManage',
            icon: 'PropertySafety',
            path: '/workProcess/invoiceManage',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/workProcess/invoiceManage',
                redirect: '/workProcess/invoiceManage/list',
              },
              {//后工序发票列表
                name: 'invoiceList',
                icon: 'home',
                path: '/workProcess/invoiceManage/list',
                component: './operation/workProcess/invoiceManage/List',
              },
              {//后工序发票详情
                name: 'invoiceList',
                icon: 'home',
                path: '/workProcess/invoiceManage/detail',
                component: './operation/workProcess/invoiceManage/List/PartSalesInvoice/Detail',
              },
              {//后工序发票申请
                name: 'invoiceSave',
                path: '/workProcess/invoiceManage/save',
                component: './operation/workProcess/invoiceManage/List/PartSalesInvoice/save',
                hideInMenu: true,
              },
              {//后工序发票修改
                name: 'invoiceEdit',
                path: '/workProcess/invoiceManage/edit',
                component: './operation/workProcess/invoiceManage/List/PartSalesInvoice/edit',
                hideInMenu: true,
              },
            ]
          },
        ]
      },
      {
        name: 'deliver',
        icon: 'SendOutlined',
        path: '/deliver',
        routes: [
          {
            path: '/deliver',
            redirect: '/deliver/can-deliver-list',
          },
          {
            name: 'can-deliver-list',
            icon: 'car',
            path: '/deliver/can-deliver-list',
            component: './operation/deliver/CanDeliverList',
          },
          {
            name: 'delivered-list',
            icon: 'car',
            path: '/deliver/delivered-list',
            component: './operation/deliver/DeliveredList',
          },
          //yzl-工序待发货
          {
            name: 'can-deliver-procedure-list',
            icon: 'car',
            path: '/deliver/can-deliver-procedure-list',
            component: './operation/deliver/CanDeliverProcedureList',
          },
         //yzl-合并发货单（马尼）
          {
            name: 'can-deliver-mergeDocuments-list',
            icon: 'car',
            path: '/deliver/can-deliver-mergeDocuments-list',
            component: './operation/deliver/CanDeliverMergeDocumentsList',
          },
          {
            name: 'detail',
            path: '/deliver/detail',
            component: './operation/deliver/Detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'form',
            path: '/deliver/form',
            component: './operation/deliver/Form',
            hideInMenu: true,
            showBack:true,
          },
          //物流管理
          {
            name: 'logistic-list',
            icon: 'car',
            path: '/deliver/logistic-list',
            component: './operation/deliver/logistic',
          },
          {
            name: 'logistic-order',
            path: '/deliver/logistic-order',
            icon:'car',
            component: './operation/deliver/logistic/Form/orderindex',
            hideInMenu: true,
            showBack:true,
          },
          //货拉拉
          {
            name: 'huolala-logistic-list',
            icon: 'car',
            path: '/deliver/huolala-logistic-list',
            component: './operation/deliver/logisticHuolala',
          },
          {
            name: 'huolala-logistic-order',
            path: '/deliver/huolala-logistic-order',
            icon:'car',
            component: './operation/deliver/logisticHuolala/Form/orderindex',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'huolala-logistic-detail',
            path: '/deliver/material-purchase/huolala-logistic-detail',
            icon:'gateway',
            component: './operation/deliver/logisticHuolala/detail/index',
            hideInMenu: true,
            showBack:true,
          },

          //物流公司管理
          {
            name: 'transport-list',
            icon: 'car',
            path: '/deliver/transport-list',
            component: './operation/deliver/transport',
          },
        ]
      },
      {
        name: 'logistics',
        icon: 'car',
        path: '/logistics',
        routes: [
          {
            path: '/logistics',
            redirect: '/logistics/list',
          },
          {
            path: '/logistics/list',
            name: 'list',
            component: './operation/logistics/List',
            hideInMenu: true,
          },
          {
            path: '/logistics/form',
            name: 'list',
            component: './operation/logistics/Form',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/logistics/route',
            name: 'list',
            component: './operation/logistics/Route',
            hideInMenu: true,
            showBack:true,
          },
        ]
      },
      {
        name:'freight',
        icon:'car',
        path:'/freight',
        routes:[
          {
            path:'/freight',
            redirect: '/freight/list',
          },
          {
            path:'/freight/list',
            name:'list',
            component: './operation/freight/List',
            hideInMenu: true,
          },
        ]
      },
      {
        name: 'settlement',
        icon: 'MoneyCollect',
        path: '/settlement',
        routes: [
          {
            path: '/settlement',
            redirect: '/settlement/list',
          },
          {
            path: '/settlement/list',
            name: 'list',
            icon: 'MoneyCollect',
            component: './operation/settlement/List',
          },
          {
            path: '/settlement/settlement_mtSale/addMtSale',
            name: 'settlement_addMtSale',
            component: './operation/settlement/settlement_mtSale/materialSave',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlement_mtSale/addMtSaleExcelint',
            name: 'settlement_addMtSaleExcelint',
            component: './operation/settlement/settlement_mtSale/materialSaveExcelint',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDetail',
            name: 'settlement_mtSaleDetail',
            component: './operation/settlement/settlement_mtSale/MtSaleDetailed',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDeliverDetail',
            name: 'settlement_mtSaleDeliverDetail',
            component: './operation/settlement/settlement_mtSale/MtSaleDetailed/MtOrderDeliverDetailed',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlement_mtSale/mtSaleDeliver',
            name: 'settlement_mtSaleDeliver',
            component: './operation/settlement/settlement_mtSale/deliver',
            hideInMenu: true,
            showBack:true,
          },
          //形态转换
          {
            name: 'materialTransformation-list',
            icon: 'car',
            path: '/settlement/materialTransformation/materialTransformation-list',
            component: './operation/settlement/materialTransformation',
          },
          {
            name: 'materialTransformation-save',
            path: '/settlement/materialTransformation/materialTransformation-save',
            icon:'car',
            component: './operation/settlement/materialTransformation/Form/index',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementPaymentPlan',
            name: 'settlementPaymentPlan',
            icon: 'Transaction',
            component: './operation/settlement/paymentPlan',
          },
          {
            path: '/settlement/settlementCenter',
            name: 'settlementCenter',
            icon: 'MoneyCollect',
            component: './operation/settlement/settlementCenter',
          },
          {
            path: '/settlement/settlementCenter/settlementDetail',
            name: 'settlementDetail',
            component: './operation/settlement/settlementCenter/settlementDetail',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementCenter/paymentApplyDetail',
            name: 'paymentApplyDetail',
            component: './operation/settlement/settlementCenter/paymentApplyDetail',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementCenter/paymentApply',
            name: 'settlementCenter_PaymentApply',
            component: './operation/settlement/settlementCenter/paymentApplication',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementPaymentPlan/settlementDetail',
            name: 'planSettlementDetail',
            component: './operation/settlement/paymentPlan/settlementDetail',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/settlement/settlementInvoice',
            name: 'settlementInvoice',
            icon: 'PropertySafety',
            component: './operation/settlement/invoice',
          },
          {
            path: '/settlement/prodList',
            name: 'prodList',
            icon: 'ShareAlt',
            component: './operation/order/prodOrder/List',
          },
          // {
          //   path: '/settlement/procedureOrderList',
          //   name: 'procedureOrderList',
          //   icon: 'ShareAlt',
          //   component: './operation/order/procedureOrder/List',
          // },
          {
            path: '/settlement/settlement_mtSale',
            name: 'settlement_mtSale',
            icon: 'Shopping',
            component: './operation/settlement/settlement_mtSale',
          },
          {
            path: '/settlement/partsPurchaseList',
            name: 'partsPurchaseList',
            icon: 'ShareAlt',
            component: './operation/productLibrary/PartsPurchase',
          },
          {
            path: '/settlement/claimList',
            name: 'claimList',
            icon: 'ShareAlt',
            component: './operation/postSale/claimManagement',
          },
        ]
      },
      {
        name: 'supplement',
        icon: 'car',
        path: '/supplement',
        routes: [
          {
            path: '/supplement',
            redirect: '/supplement/can-supplement-list',
          },
          {
            name: 'can-supplement-list',
            icon: 'car',
            path: '/supplement/can-supplement-list',
            component: './operation/supplement/CanSupplementList',
          },

          {
            name: 'supplement-applying-list',
            icon: 'car',
            path: '/supplement/supplement-applying-list',
            component: './operation/supplement/ApplySupplementList',
          },
          {
            name: 'reject-supplement-list',
            icon: 'car',
            path: '/supplement/reject-supplement-list',
            component: './operation/supplement/RejectSupplementList',
          },
          {
            name: 'supplemented-list',
            icon: 'car',
            path: '/supplement/supplemented-list',
            component: './operation/supplement/SupplementedList',
          },
          {
            //不良损失费用
            name: 'badLoss-list',
            icon: 'car',
            path: '/supplement/badLoss-list',
            component: './operation/supplement/badLossList',
          },

          {
            name: 'detail',
            path: '/supplement/detail',
            component: './operation/supplement/Detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'apply-form',
            path: '/supplement/form',
            component: './operation/supplement/Form',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'PatchDetails',
            path: '/supplement/PatchDetails',
            component: './operation/supplement/ApplySupplementList/PatchDetails',
            hideInMenu: true,
            showBack:true,
          },
        ]
      },
      {
        name: 'postSale',
        icon: 'BlockOutlined',
        path: '/postSale',
        routes: [
          {
            path: '/postSale',
            redirect: '/postSale/afterSaleList',
          },
          // {
          //   name: 'postSale-index',
          //   path: '/postSale/index',
          //   icon: 'UserOutlined',
          //   component: './operation/postSale/Index',
          // },
          {
            name: 'postSale-list',
            path: '/postSale/afterSaleList',
            icon: 'BlockOutlined',
            component: './operation/postSale/afterSaleList',
          },
          {
            name: 'sale_detail',
            path: '/postSale/afterSaleList/detail',
            component: './operation/postSale/afterSaleList/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'claimList',
            path: '/postSale/claimList',
            icon: 'FileProtectOutlined',
            component: './operation/postSale/claimManagement',
          },
          {
            name: 'claim',
            path: '/postSale/components/claim',
            component: './operation/postSale/components/claim/claim',
            hideInMenu: true,
          },
          {
            name: 'FreeAudit',
            path: '/postSale/components/FreeAudit',
            component: './operation/postSale/components/FreeAudit',
            hideInMenu: true,
          },
          {
            name: 'claimList_detail',
            path: '/postSale/claimListDetail',
            component: './operation/postSale/claimManagement/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'return_repair',
            path: '/postSale/return_repair',
            icon: 'InteractionOutlined',
            component: './operation/postSale/return_repair',
          },
          {
            name: 'missing_parts',
            path: '/postSale/missing_parts',
            icon: 'MacCommandOutlined',
            component: './operation/postSale/missing_parts',
          },

          {
            name: 'scrap_supplement',
            path: '/postSale/scrap_supplement',
            icon: 'UngroupOutlined',
            component: './operation/postSale/scrap_supplement',
          },
          {
            name: 'concession_receive',
            path: '/postSale/concession_receive',
            icon: 'OneToOneOutlined',
            component: './operation/postSale/concession_receive',
          },
          {
            name: 'return_cancel',
            path: '/postSale/return_cancel',
            icon: 'ImportOutlined',
            component: './operation/postSale/return_cancel',
          },
          {
            name: 'otherClaim',
            path: '/postSale/otherClaim',
            icon: 'AppstoreOutlined',
            component: './operation/postSale/otherClaim',
          },
          {
            name: 'order_detail',
            path: '/postSale/order_detail',
            component: './operation/postSale/order_detail',
            hideInMenu: true,
          },
          {
            name: 'badReportList',
            path: '/postSale/badReportList',
            icon: 'BugOutlined',
            component: './operation/postSale/adverseReportList',
          },
        ]
      },
      // 品质管控
      {
        name: 'qualityControl',
        icon: 'project',
        path: '/qualityControl',
        routes:[
          {
            path:'/qualityControl',
            redirect: '/qualityControl/dataLibrary'
          },
          {
            name:'dataLibrary',
            icon:'project',
            path:'/qualityControl/dataLibrary',
            component:'./operation/qualityControl/dataLibrary'
          },
          {
            name: 'dataLibraryDetail',
            path: '/qualityControl/dataLibrary/detail',
            component: './operation/qualityControl/dataLibrary/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'share-alt',
            name: 'experienceSharing',
            path: '/qualityControl/experienceSharing',
            component: './operation/qualityControl/experienceSharing',
          },
          {
            icon: 'share-alt',
            name: 'edit_experienceSharing',
            path: '/qualityControl/components/AddShareArticle',
            component: './operation//qualityControl/components/AddShareArticle',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'experienceSharing',
            path: '/qualityControl/experienceSharing/detail',
            component: './operation/qualityControl/experienceSharing/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'container',
            name: 'newsCenter',
            path: '/qualityControl/newsCenter',
            component: './operation/qualityControl/newsCenter',
          },
          {
            name: 'newsCenterDetail',
            path: '/qualityControl/newsCenter/detail',
            component: './operation/qualityControl/newsCenter/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'play-square',
            name: 'video',
            path: '/qualityControl/video',
            component: './operation/qualityControl/video',
          },
          {
            icon: 'calendar',
            name: 'train',
            path: '/qualityControl/train',
            component: './operation/qualityControl/train',
          },
          {
            icon: 'calendar',
            name: 'trainForm',
            path: '/qualityControl/train/form',
            component: './operation/qualityControl/train/Form',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'calendar',
            name: 'assessment-record',
            path: '/qualityControl/train/assessment-record',
            component: './operation/qualityControl/train/assessment-record',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'calendar',
            name: 'assessment-record-detail',
            path: '/qualityControl/train/assessment-record-detail',
            component: './operation/qualityControl/train/detail/assessment-record-detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'check-square',
            name: 'spotCheck',
            path: '/qualityControl/spotCheck',
            component: './operation/qualityControl/spotCheck',
          },
          {
            icon: 'FileProtectOutlined',
            name: 'non-performingBill',
            path: '/qualityControl/nonPerformingBill',
            component: './operation/qualityControl/nonPerformingBill/HandsontableExcel',
          }, {
            icon: 'FileProtectOutlined',
            name: 'non-performingBill-List',
            path: '/qualityControl/nonPerformingBillList',
            component: './operation/qualityControl/nonPerformingBill/List/index',
          },
          {
            icon: 'check-square',
            name: 'spotCheckForm',
            path: '/qualityControl/spotCheck/form',
            component: './operation/qualityControl/spotCheck/form',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'check-square',
            name: 'spotCheckResult',
            path: '/qualityControl/spotCheck/spotCheckResult',
            component: './operation/qualityControl/spotCheck/spotCheckResult',
            hideInMenu: true,
            showBack:true,
          },
          {
            icon: 'check-square',
            name: 'spotCheckDetail',
            path: '/qualityControl/spotCheck/spotCheckDetail',
            component: './operation/qualityControl/spotCheck/detail',
            hideInMenu: true,
            showBack:true,
          },
          //产品不良率
          {
            icon: 'container',
            name: 'rejectRatio',
            path: '/qualityControl/rejectRatio',
            component: './operation/qualityControl/rejectRatio',
          },
          //修改产品不良率
          {
            icon: 'container',
            name: 'rejectRatioForm',
            path: '/qualityControl/rejectRatio/Form',
            component: './operation/qualityControl/rejectRatio/Form',
            hideInMenu:true,
            showBack: true,
          },


          {
            icon: 'check-square',
            name: 'badRateFactory',
            path: '/qualityControl/badRateFactory',
            component: './operation/qualityControl/badRateFactory',
          },//工厂PPM/COPQ不良率
          {
            icon: 'check-square',
            name: 'badRateFactoryForm',
            path: '/qualityControl/badRateFactory/Form',
            component: './operation/qualityControl/badRateFactory/Form',
            hideInMenu: true,
            showBack:true,
          },//工厂PPM/COPQ不良率修改
        ]
      },
      {
        name: 'product-library',
        path: '/product-library',
        icon: 'appstore',
        routes: [
          {
            path: '/list',
            redirect: '/product-library/list',
          },
          {
            name: 'list',
            icon: 'car',
            path: '/product-library/list',
            component: './operation/productLibrary/List',
          },
          {
            name: 'stock-taking-form',
            icon: 'car',
            path: '/product-library/stock-taking-form',
            hideInMenu: true,
            component: './operation/productLibrary/StockTakingForm',
            showBack:true,
          },
          {
            name: 'product-ProductOutOperation',
            path: '/product-library/product-ProductOutOperation',
            icon:'experiment',
            component: './operation/productLibrary/List/ProductOutOperation',
            hideInMenu: true,
            showBack:true,
          },//产品盘点出库
          {
            name: 'product-ProductInOperation',
            path: '/product-library/product-ProductInOperation',
            icon:'experiment',
            component: './operation/productLibrary/List/ProductInOperation',
            hideInMenu: true,
            showBack:true,
          },//产品盘点入库
          //后工序产品yzl
          /*{
            name: 'partsProcedure-list',
            icon: 'car',
            path: '/product-library/partsProcedure-list',
            component: './operation/productLibrary/PartsProcedure',
          },*/
         /* {
            name: 'partsProcedure-stock-taking-form',
            icon: 'car',
            path: '/product-library/partsProcedure-stock-taking-form',
            hideInMenu: true,
            component: './operation/productLibrary/ProcedureStockTakingForm',
            showBack:true,
          },*/

          {
            name: 'storehouse-in-list',
            icon: 'car',
            path: '/product-library/storehouse-in-list',
            component: './operation/productLibrary/StorehouseInList',
          },
          {
            name: 'storehouse-in-detail',
            icon: 'car',
            path: '/product-library/storehouse-in-detail',
            hideInMenu: true,
            component: './operation/productLibrary/StorehouseInList/detail',
            showBack:true,
          },
            {
            name: 'productStorage',
            icon: 'car',
            path: '/product-library/productStorage',
            component: './operation/productLibrary/ProductStorage',
          },  {
            name: 'productStorageForm',
            icon: 'car',
            path: '/product-library/productStorage/Form',
            hideInMenu: true,
            component: './operation/productLibrary/ProductStorage/Form',
            showBack:true,
          },
          {
            name: 'storehouse-out-list',
            icon: 'car',
            path: '/product-library/storehouse-out-list',
            component: './operation/productLibrary/StorehouseOutList',
          },
          {
            name: 'storehouse-out-detail',
            icon: 'car',
            hideInMenu: true,
            path: '/product-library/storehouse-out-detail',
            component: './operation/productLibrary/StorehouseOutList/detail',
            showBack:true,
          },
          {
            name: 'partsPurchase-list',
            icon: 'car',
            path: '/product-library/partsPurchase-list',
            component: './operation/productLibrary/PartsPurchase',
          },
          {
            name: 'partsPurchase-detail',
            path: '/product-library/partsPurchase-detail',
            icon:'car',
            component: './operation/productLibrary/PartsPurchase/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'partsPurchase-save',
            path: '/product-library/partsPurchase-save',
            icon:'car',
            component: './operation/productLibrary/PartsPurchase/Form/partindex',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'partsPurchase-order',
            path: '/product-library/partsPurchase-order',
            icon:'car',
            component: './operation/productLibrary/PartsPurchase/Form/orderindex',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'partsPurchase-warehousing',
            icon: 'experiment',
            path: '/product-library/partsPurchase-warehousing',
            component: './operation/productLibrary/PartsPurchase/part/index',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'productJournal-list',
            icon: 'car',
            path: '/product-library/productJournal-list',
            component: './operation/productLibrary/ProductJournal',
          },
        ]
      },
      {
        name: 'finance',
        icon: 'DollarOutlined',
        path: '/finance',
        routes: [
          {
            path: '/finance',
            redirect: '/finance/list',
          },
          {
            name: 'list',
            icon: 'DollarOutlined',
            path: '/finance/list',
            component: './operation/finance/List',
          },
          //客户账期路由
          {
            name: 'customer-period',
            icon: 'DollarOutlined',
            path: '/finance/customer-period',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/customer-period',
                redirect: '/finance/customer-period/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/customer-period/list',
                component: './operation/finance/CustomerPeriod/List'
              },
            ]
          },
          //发票管理路由
          {
            name: 'invoice-information',
            icon: 'DollarOutlined',
            path: '/finance/invoice-information',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/invoice-information',
                redirect: '/finance/invoice-information/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/invoice-information/list',
                component: './operation/finance/InvoiceInformation/List'
              },
            ]
          },
          //收款计划路由
          {
            name: 'collection-plan',
            icon: 'DollarOutlined',
            path: '/finance/collection-plan',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/collection-plan',
                redirect: '/finance/collection-plan/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/list',
                component: './operation/finance/CollectionPlan/List'
              },
              //收款计划定金改款路由
              {
                name: 'depositModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/depositModification',
                component: './operation/finance/CollectionPlan/DepositModification'
              },
              //收款计划定金核销路由
              {
                name: 'depositWriteOff',
                icon: 'home',
                path: '/finance/collection-plan/depositWriteOff',
                component: './operation/finance/collectionPlan/DepositWriteOff',
                showBack:true,
              },
              //收款计划进度款改款路由
              {
                name: 'progressModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/progressModification',
                component: './operation/finance/CollectionPlan/ProgressModification'
              },
              //收款计划进度款核销路由
              {
                name: 'paymentWriteOff',
                icon: 'home',
                path: '/finance/collection-plan/paymentWriteOff',
                component: './operation/finance/collectionPlan/PaymentWriteOff',
                showBack:true,
              },
              //收款计划尾款审核
              {
                name: 'toBeReviewed',
                icon: 'home',
                path: '/finance/collection-plan/toBeReviewed',
                component: './operation/finance/collectionPlan/toBeReviewed',
                showBack:true,
              },
              //收款计划预收款审核
              {
                name: 'advanceReviewed',
                icon: 'home',
                path: '/finance/collection-plan/advanceReviewed',
                component: './operation/finance/collectionPlan/advanceReviewed',
                showBack:true,
              },
              // AdvanceReviewed
              //收款计划尾款改款路由
              {
                name: 'balanceModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/balanceModification',
                component: './operation/finance/CollectionPlan/balanceModification'
              },
              //收款计划预收款改款路由
              {
                name: 'advanceModification',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/advanceModification',
                component: './operation/finance/CollectionPlan/advanceModification'
              },
              {
                name: 'splitOrder',
                icon: 'UserOutlined',
                path: '/finance/collection-plan/splitOrder',
                component: './operation/finance/CollectionPlan/SplitOrder'
              },
              //收款计划尾款核销路由
              {
                name: 'balanceWriteOff',
                icon: 'home',
                path: '/finance/collection-plan/balanceWriteOff',
                component: './operation/finance/collectionPlan/BalanceWriteOff',
                showBack:true,
              },
              //收款计划待确认尾款提交路由
              {
                name: 'balanceSubmit',
                icon: 'home',
                path: '/finance/collection-plan/balanceSubmit',
                component: './operation/finance/collectionPlan/BalanceSubmit',
                showBack:true,
              },
              //收款计划尾款增收预付款路由
              {
                name: 'advancePayment',
                icon: 'home',
                path: '/finance/collection-plan/advancePayment',
                component: './operation/finance/collectionPlan/AdvancePayment',
                showBack:true,
              },
              //收款计划-发票对冲
              {
                name: 'invoiceOffset',
                icon: 'home',
                path: '/finance/collection-plan/invoiceOffset',
                component: './operation/finance/CollectionPlan/InvoiceOffset'
              },
            ]
          },
          //收付款协议档案路由
          {
            name: 'payment-agreement',
            icon: 'DollarOutlined',
            path: '/finance/payment-agreement',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/finance/payment-agreement',
                redirect: '/finance/payment-agreement/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/finance/payment-agreement/list',
                component: './operation/finance/PaymentAgreement/List'
              },
            ]
          },
        ]
      },
      {
        name: 'finance-report',
        icon: 'FundProjectionScreenOutlined',
        path: '/finance-report',
        routes: [
          {
            name: 'finance-produce',
            icon: 'printer',
            path: '/finance-report/finance-produce',
            component: './operation/financeReport/produce',
          },
          {
            name: 'finance-scrap',
            icon: 'printer',
            path: '/finance-report/finance-scrap',
            component: './operation/financeReport/scrap',
          } ,{//路由
            name: 'finance-subcontract',
            icon: 'printer',
            path: '/finance-report/finance-subcontract',
            component: './operation/financeReport/subcontract/index',
          }, //采购订单报表
          {
            name: 'purchase-order',
            icon: 'car',
            path: '/finance-report/purchase-order',
            component: './operation/financeReport/purchaseOrder',
          },
          //采购入库单报表
          {
            name: 'purchase-warehouse',
            icon: 'build',
            path: '/finance-report/purchase-warehouse',
            component: './operation/financeReport/purchaseWarehouse',
          },
          //材料出库单报表
          {
            name: 'material_delivery',
            icon: 'experiment',
            path: '/finance-report/material_delivery',
            component: './operation/financeReport/materialDelivery',
          },
          //销售订单报表
          {
            name: 'sales-order',
            icon: 'solution',
            path: '/finance-report/sales-order',
            component: './operation/financeReport/salesOrder',
          },
          //销售价格订单报表
          {
            name: 'salesPrice-order',
            icon: 'solution',
            path: '/finance-report/salesPrice-order',
            component: './operation/financeReport/salesPriceOrder',
          },
         // 销售发货报表
          {
            name: 'sales-shipment',
            icon: 'solution',
            path: '/finance-report/sales-shipment',
            component: './operation/financeReport/salesShipment',
          },
          // 废钢购入erp报表
          {
            name: 'scrap-purchase',
            icon: 'solution',
            path: '/finance-report/scrap-purchase',
            component: './operation/financeReport/scrapPurchase',
          },
          // 销售订单明细执行报表
          {
            name: 'order-execution',
            icon: 'solution',
            path: '/finance-report/order-execution',
            component: './operation/financeReport/orderExecution',
          },
          // YM阿米巴毛利率年度报表
          {
            name: 'finance-ymAmbGrossProfitAnnual',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbGrossProfitAnnual',
            component: './operation/financeReport/ymAmbGrossProfitAnnual',
          },
          // YM阿米巴毛利率部门报表
          {
            name: 'finance-ymAmbGrossProfitDept',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbGrossProfitDept',
            component: './operation/financeReport/ymAmbGrossProfitDept',
          },
          // YM阿米巴部门报表
          {
            name: 'finance-ymAmbDeptReport',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbDeptReport',
            component: './operation/financeReport/ymAmbDeptReport',
          },
          //阿米巴报表图表
          {
            name: 'finance-ymAmbProfitReportChart',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbProfitReportChart',
            component: './operation/financeReport/ymAmbProfitReportChart',
          },
          // YM阿米巴毛利率月度报表
          {
            name: 'finance-ymAmbGrossProfit',
            icon: 'solution',
            path: '/finance-report/finance-ymAmbGrossProfit',
            component: './operation/financeReport/ymAmbGrossProfit',
          },
          {
            name: 'ymAmbGrossProfit-transportation',
            path: '/finance-report/ymAmbGrossProfit-transportation',
            icon:'car',
            component: './operation/financeReport/ymAmbGrossProfit/Form/transportationindex',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'ymAmbGrossProfit-overdue',
            path: '/finance-report/ymAmbGrossProfit-overdue',
            icon:'car',
            component: './operation/financeReport/ymAmbGrossProfit/Form/overdueindex',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'ymAmbGrossProfit-claimant',
            path: '/finance-report/ymAmbGrossProfit-claimant',
            icon:'car',
            component: './operation/financeReport/ymAmbGrossProfit/Form/claimantindex',
            hideInMenu: true,
            showBack:true,
          },
        ]
      },
      {
        name: 'returns',
        icon: 'box-plot',
        path: '/returns',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/returns',
            redirect: '/returns/list',
          },
          {
            name: 'list',
            icon: 'box-plot',
            path: '/returns/list',
            component: './operation/returns',
          }
        ]
      },
      // {
      //   name: 'work-report',
      //   icon: 'project',
      //   path: '/work-report',
      //   hideChildrenInMenu: true,
      //   routes: [
      //     {
      //       path: '/work-report',
      //       redirect: '/work-report/list',
      //     },
      //     {
      //       name: 'list',
      //       icon: 'home',
      //       path: '/work-report/list',
      //       component: './operation/workReport/List',
      //     },
      //     {
      //       name: 'list',
      //       path: '/work-report/list/reportDetail',
      //       component: './operation/workReport/List/Detail/ReportDetail',
      //       hideInMenu: true,
      //     },
      //   ]
      // },

      {
        name: 'logManagement',
        icon: 'file-protect',
        path: '/logManagement',
        routes: [
          {
            path: '/logManagement',
            redirect: '/logManagement/orderLog',
          },
          {
            name: 'orderLog',
            icon: 'account-book',
            path: '/logManagement/orderLog',
            component: './operation/logManagement/orderLog'
          },
          {
            name: 'XMLDetail',
            path: '/logManagement/XMLLog/XMLDetail',
            component: './operation/logManagement/orderLog/XMLDetail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'receiptLog',
            icon: 'build',
            path: '/logManagement/receiptLog',
            component: './operation/logManagement/receiptLog'
          },
          {
            name: 'materialPurchaseLog',
            icon: 'build',
            path: '/logManagement/materialPurchaseLog',
            component: './operation/logManagement/materialPurchaseLog'
          },
          {
            name: 'partsPurchaseLog',
            icon: 'build',
            path: '/logManagement/partsPurchaseLog',
            component: './operation/logManagement/partsPurchaseLog'
          },
          {
            name: 'deliverLog',
            icon: 'car',
            path: '/logManagement/deliverLog',
            component: './operation/logManagement/deliverLog'
          },
          {
            name: 'materialCustomerLog',
            icon: 'experiment',
            path: '/logManagement/materialCustomerLog',
            component: './operation/logManagement/materialCustomerLog'
          },
          {
            name: 'unNumberReportLog',
            icon: 'apartment',
            path: '/logManagement/unNumberReportLog',
            component: './operation/logManagement/unNumberReportLog'
          },
          {
            name: 'unQualityReportLog',
            icon: 'pull-request',
            path: '/logManagement/unQualityReportLog',
            component: './operation/logManagement/unQualityReportLog'
          },
          {
            name: 'programLog',
            icon: 'windows',
            path: '/logManagement/programLog',
            component: './operation/logManagement/programLog'
          },
          {
            name: 'materialAllotLog',
            icon: 'setting',
            path: '/logManagement/materialAllotLog',
            component: './operation/logManagement/materialAllotLog'
          },
          {
            name: 'systemLog',
            icon: 'appstore',
            path: '/logManagement/systemLog',
            component: './operation/logManagement/systemLog'
          },
          {
            name: 'Interface',
            icon: 'api',
            path: '/logManagement/Interface',
            component: './operation/logManagement/interface'
          },
          {
            name: 'integrationService',
            icon: 'api',
            path: '/logManagement/integrationService',
            component: './operation/logManagement/integrationService'
          },
          {
            name: 'programChk',
            icon: 'api',
            path: '/logManagement/integrationService/programChk',
            component: './operation/logManagement/integrationService/programChk'
          },
        ]
      },
      {
        name: 'customer',
        icon: 'usergroup-add',
        path: '/customer',
        routes: [
          {
            path: '/customer',
            redirect: '/customer/user',
          },
          {
            name: 'user',
            icon: 'user-add',
            path: '/customer/user',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/user',
                redirect: '/customer/user/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/customer/user/list',
                component: './operation/customer/user/List'
              },
              {
                name: 'detail',
                icon: 'UserOutlined',
                path: '/customer/user/detail',
                showBack:true,
              },
            ]
          },
          {
            name: 'address',
            icon: 'environment',
            path: '/customer/address',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/address',
                redirect: '/customer/address/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/customer/address/list',
                component: './operation/customer/address'
              }
            ]
          },
          {
            name: 'invoice',
            icon: 'file-protect',
            path: '/customer/invoice',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/invoice',
                redirect: '/customer/invoice/list',
              },
              {
                name: 'list',
                icon: 'UserOutlined',
                path: '/customer/invoice/list',
                component: './operation/customer/invoice'
              }
            ]
          },
          {
            name: 'part',
            icon: 'tool',
            path: '/customer/part',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/customer/part',
                redirect: '/customer/part/manage',
              },
              {
                name: 'manage',
                icon: 'UserOutlined',
                path: '/customer/part/manage',
                component: './operation/customer/part/PartManage',
              },
              {
                name: 'form',
                icon: 'UserOutlined',
                path: '/customer/part/form',
                showBack:true,
                component: './operation/customer/part/PartDetails'
              },
              {
                name: 'detail',
                icon: 'UserOutlined',
                path: '/customer/part/detail',
                component: './operation/customer/part/Detail',
                showBack:true,
              },
            ]
          },
          {
            name: 'custom-template',
            icon: 'file-protect',
            path: '/customer/custom-template',
            component: './operation/customer/customTemplate/List',
          },
          {
            name: 'consultService',
            icon: 'profile',
            path: '/customer/consultService',
            component: './operation/customer/ConsultService',
          },
        ]
      },
      {
        name: 'supplier',
        path: '/supplier',
        icon: 'robot',
        // hideChildrenInMenu: true,
        routes:[
          {
            path: '/supplier',
            redirect:'/supplier/list',
          },
          {
            name: 'firstContact',
            icon: 'SettingOutlined',
            path: '/supplier/firstContact',
            component: './operation/cuttingSupplier/firstContact',
          },
          {
            name: 'groupPermiss',
            icon: 'SettingOutlined',
            path: '/supplier/groupPermiss',
            component: './operation/cuttingSupplier/groupPermiss',
          },
          {
            name: 'groupMember',
            path: '/supplier/groupPermiss/groupMember',
            component: './operation/cuttingSupplier/groupPermiss/Form/MemberForm',
            showBack: true,
            hideInMenu: true,
          },
          {
            name: 'supFunctionManage',
            icon: 'SettingOutlined',
            path: '/supplier/supFunctionManage',
            component: './operation/cuttingSupplier/supFunctionManage',
          },
          {
            name: 'subAccount',
            path: '/supplier/supSubAccount',
            hideInMenu: true,
            component: './operation/cuttingSupplier/supSubAccount',
            showBack: true,
          },
          {
            name: 'supplier',
            icon: 'SettingOutlined',
            path: '/supplier/list',
            component: './operation/cuttingSupplier/CuttingSupplierManagement',
          },
          {
            name: 'supplier.detail',
            path: '/supplier/detail',
            component: './operation/cuttingSupplier/unitInformation',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'supplierAdd',
            path: '/supplier/add',
            component: './operation/cuttingSupplier/Form',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'supplierAdd',
            path: '/supplier/addEquipment',
            component: './operation/cuttingSupplier/deviceInformation/addEquipment',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'supplierDevice-information',
            path: '/supplier/device-information',
            component: './operation/cuttingSupplier/deviceInformation',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/supplier/login-information',
            component: './operation/cuttingSupplier/CuttingSupplierLoginInfo',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/supplier/CutUnitPriceInfo',
            component: './operation/cuttingSupplier/CutUnitPriceInfoDetail',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/supplier/FactorySupplierAdd',
            component: './operation/cuttingSupplier/FactorySupplier',
            hideInMenu: true,
            showBack:true,
          },
          {
            path: '/supplier/CutUnitPriceInfoCopy',
            component: './operation/cuttingSupplier/CopySupplier',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-supplier',
            path: '/supplier/material-supplier',
            icon:'solution',
            component: './operation/materialLibrary/materialSupplier/List',
          },
          {
            name: 'cameraYsList',
            path: '/supplier/cameraYsList',
            icon:'SettingOutlined',
            component: './operation/cuttingSupplier/CameraYsList',
          },
          {
            name: 'Op_supInvoice',
            icon: 'SettingOutlined',
            path: '/supplier/supInvoice',
            component: './operation/cuttingSupplier/invoice',
          },
          {
            name: 'Op_supAddress',
            icon: 'SettingOutlined',
            path: '/supplier/supAddress',
            component: './operation/cuttingSupplier/address',
          },
        ]
      },
      {
        name: 'material-library',
        path: '/material-library',
        icon: 'gold',
        routes: [
          {
            name: 'material-science',
            path: '/material-library/material-science',
            icon:'container',
            component: './operation/materialLibrary/materialScience',
          },
          {
            name: 'material-science-form',
            path: '/material-library/material-science-form',
            icon:'experiment',
            component: './operation/materialLibrary/materialScience/steelPlate/Form/index',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-surplus',
            path: '/material-library/material-surplus',
            icon:'shopping-cart',
            component: './operation/materialLibrary/materialSurplus',
          },
          {
            name: 'material-summary',
            path: '/material-library/material-statistic/material-summary',
            icon:'shopping-cart',
            component: './operation/materialLibrary/materialSurplus/statistics/materialSummary',
          },
          {
            name: 'material-convert',
            path: '/material-library/material-statistic/material-convert',
            icon:'shopping-cart',
            component: './operation/materialLibrary/materialTypeConvert/TypeConvert',
          },
          {
            name: 'material-convert-add',
            path: '/material-library/material-statistic/material-convert-add',
            icon:'shopping-cart',
            component: './operation/materialLibrary/materialTypeConvert/index',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-surplus-form',
            path: '/material-library/material-surplus-form',
            icon:'fork',
            component: './operation/materialLibrary/materialSurplus/steelPlate/Form',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-allocation',
            path: '/material-library/material-allocation',
            icon:'pull-request',
            component: './operation/materialLibrary/MaterialAllocation',
          },
          {
            path: '/material-library/material-allocation/detail',
            icon:'solution',
            component: './operation/materialLibrary/MaterialAllocationDetail',
          },
          {
            path: '/material-library/material-allocation/add',
            icon:'experiment',
            component: './operation/materialLibrary/AddMaterialAllocation',
          },
          {
            name: 'choice-issue',
            path: '/material-library/choice-issue',
            icon:'car',
            component: './operation/materialLibrary/materialStockOut/component/stockIndex',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-purchase',
            path: '/material-library/material-purchase',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            // component: './operation/materialLibrary/materialPurchase/MaterialPurchase',
            routes: [
              {
                path: '/material-library/material-purchase',
                redirect:'/material-library/material-purchase/list',
              },
              {
                name: 'list',
                path: '/material-library/material-purchase/list',
                icon:'deployment-unit',
                component: './operation/materialLibrary/materialPurchase/MaterialPurchase',
              },
              {
                name: 'detail',
                path: '/material-library/material-purchase/detail',
                icon:'gateway',
                component: './operation/materialLibrary/materialPurchase/detail',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'save',
                path: '/material-library/material-purchase/save',
                icon:'gateway',
                component: './operation/materialLibrary/materialPurchase/Form/index',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'saveProfile',
                path: '/material-library/material-purchase/saveProfile',
                icon:'gateway',
                component: './operation/materialLibrary/materialPurchase/Form/ProfileMTForm',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'modifyPurchaseForm',
                path: '/material-library/material-purchase/modify',
                component: './operation/materialLibrary/materialPurchase/Form/Modify',
                hideInMenu: true,
                showBack:true,
              },
              {
                // name: 'material-science-warehousing',
                name: 'warehousing',
                // path: '/material-library/material-science-warehousing',
                path: '/material-library/material-purchase/warehousing',
                icon:'experiment',
                component: './operation/materialLibrary/materialScience/steelPlate/Form/Purchase',
                hideInMenu: true,
                showBack:true,
              },
            ]
          },
          {
            name: 'material-stockInOut',
            path: '/material-library/material-stockInOut',
            icon:'experiment',
            // component: './operation/materialLibrary/materialStockIn/InOutStock',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-stockInOut',
                redirect:'/material-library/material-stockInOut/list',
              },
              {
                name: 'list',
                path: '/material-library/material-stockInOut/list',
                icon:'experiment',
                component: './operation/materialLibrary/materialStockIn/InOutStock',
              },
              {
                name: 'stockIn',
                path: '/material-library/material-stockInOut/stockIn',
                icon:'experiment',
                component: './operation/materialLibrary/materialStockIn',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'stockOut',
                path: '/material-library/material-stockInOut/stockOut',
                icon:'experiment',
                component: './operation/materialLibrary/materialStockOut',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'check',
                icon: 'car',
                path: '/material-library/material-stockInOut/check',
                hideInMenu: true,
                showBack:true,
                component: './operation/materialLibrary/materialStockIn/StockTakingForm',
              },
              {
                name: 'noCheck',
                icon: 'car',
                path: '/material-library/material-stockInOut/noCheck',
                hideInMenu: true,
                showBack:true,
                component: './operation/materialLibrary/materialStockIn/NoStockTakingForm',
              },
            ]
          },
          {
            name: 'material-stockInItem',
            path: '/material-library/material-stockInItem',
            icon:'experiment',
            component: './operation/materialLibrary/materialStockIn/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-notPurchaseStockIn',
            path: '/material-library/material-notPurchaseStockIn',
            icon:'experiment',
            component: './operation/materialLibrary/materialStockIn/component/NotPurchaseStockIn',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-stockOutItem',
            path: '/material-library/material-stockOutItem',
            icon:'experiment',
            component: './operation/materialLibrary/materialStockOut/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-StockOutOperation',
            path: '/material-library/material-StockOutOperation',
            icon:'experiment',
            component: './operation/materialLibrary/materialStockOut/component/StockOutOperation',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-SteelScrapStockOut',
            path: '/material-library/material-SteelScrapStockOut',
            icon: 'experiment',
            component: './operation/materialLibrary/steelScrap/StockOut/component/SteelScrapStockIndex',
            hideInMenu: true,
            showBack: true,
          },
          // {
          //   name: 'material-type-management',
          //   path: '/material-library/material-type-management',
          //   component: './operation/materialLibrary/MaterialTypeManagement',
          // },
          // {
          //   name: 'material-supplier',
          //   path: '/material-library/material-supplier',
          //   icon:'solution',
          //   component: './operation/materialLibrary/materialSupplier/List',
          // },
          {
            name: 'material-steelScrap',
            path: '/material-library/material-steelScrap',
            icon:'solution',
            component: './operation/materialLibrary/materialSteelScrap/List',
          }, {
            name: 'material-steelScrap-warehouse',
            path: '/material-library/material-steelScrap-warehouse',
            icon:'solution',
            component: './operation/materialLibrary/materialSteelScrapWarehouse/List',
          },
          {
            name: 'material-customer',
            path: '/material-library/material-customer',
            icon:'solution',
            routes: [
              {
                path: '/material-library/material-customer',
                redirect:'/material-library/material-customer/list',
              },
              {
                name: 'list',
                path: '/material-library/material-customer/list',
                icon:'solution',
                component: './operation/materialLibrary/materialCustomer/List',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'stock-in',
                path: '/material-library/material-customer/stock-in',
                icon:'solution',
                component: './operation/materialLibrary/materialCustomer/StockIn',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'stock-out',
                path: '/material-library/material-customer/stock-out',
                icon:'solution',
                component: './operation/materialLibrary/materialCustomer/StockOut',
                hideInMenu: true,
                showBack:true,
              },
            ]
          },
          // {
          //   name: 'material-supplier',
          //   path: '/material-library/material-supplier',
          //   icon:'solution',
          //   component: './operation/materialLibrary/materialSupplier/List',
          // },
          {
            name: 'steel-plate-split',
            path: '/material-library/steel-plate-split',
            icon:'experiment',
            component: './operation/materialLibrary/SteelPlateSplit',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'material-reconciliation',
            path: '/material-library/material-reconciliation',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-reconciliation',
                redirect:'/material-library/material-reconciliation/list',
              },
              {
                name: 'list',
                path: '/material-library/material-reconciliation/list',
                icon:'experiment',
                component: './operation/materialLibrary/materialReconciliation/index',
              }
            ]
          },
          {
            name: 'material-materialStock',
            path: '/material-library/material-materialStock',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-materialStock',
                redirect:'/material-library/material-materialStock/list',
              },
              {
                name: 'list',
                path: '/material-library/material-materialStock/list',
                icon:'experiment',
                component: './operation/materialLibrary/materialStock/index',
              }
            ]
          },
          {
            name: 'material-InventoryReport',
            path: '/material-library/material-InventoryReport',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/material-library/material-InventoryReport',
                redirect:'/material-library/material-InventoryReport/list',
              },
              {
                name: 'list',
                path: '/material-library/material-InventoryReport/list',
                icon:'experiment',
                component: './operation/materialLibrary/materialInventoryReport/index',
              }
            ]
          },
          {
            name: 'material-Journal',
            icon: 'car',
            path: '/material-library/material-Journal',
            component: './operation/materialLibrary/materialJournal',
          },
          {
            name: 'material-consume',
            icon: 'car',
            path: '/material-library/material-consume',
            component: './operation/materialLibrary/materialConsume',
          },
          // {
          //   name: 'consume-detail',
          //   icon: 'car',
          //   path: '/material-library/consume-detail',
          //   component: './operation/materialLibrary/materialConsume/ConsumeDetail',
          //   hideInMenu: true,
          // },
          //订单重量汇总
          {
            name: 'material-weight',
            path: '/material-library/order-weight',
            icon:'file-protect',
            component: './operation/materialLibrary/OrderWeight',
          }

        ]
      },
      {//中心仓管理
        name: 'centre-library',
        path: '/centre-library',
        icon: 'gold',
        routes: [
          {
            name: 'centre-science',
            path: '/centre-library/centre-science',
            icon:'container',
            component: './operation/centreLibrary/centreScience',
          },
          {
            name: 'centre-science-form',
            path: '/centre-library/centre-science-form',
            icon:'experiment',
            component: './operation/centreLibrary/centreScience/steelPlate/Form/index',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'centre-surplus',
            path: '/centre-library/centre-surplus',
            icon:'shopping-cart',
            component: './operation/centreLibrary/centreSurplus',
          },
          {
            name: 'centre-surplus-form',
            path: '/centre-library/centre-surplus-form',
            icon:'fork',
            component: './operation/centreLibrary/centreSurplus/steelPlate/Form',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'centre-allocation',
            path: '/centre-library/centre-allocation',
            icon:'pull-request',
            component: './operation/centreLibrary/CentreAllocation',
          },
         /* {
            path: '/centre-library/centre-allocation/detail',
            icon:'solution',
            component: './operation/centreLibrary/CentreAllocationDetail',
          },*/
          {
            path: '/centre-library/centre-allocation/add',
            icon:'experiment',
            component: './operation/centreLibrary/AddCentreAllocation',
          },
         /* {
            path: '/centre-library/centre-allocation/edit',
            icon:'experiment',
            component: './operation/centreLibrary/EditCentreAllocation',
          },*/
          {
            path: '/centre-library/centre-allocation/ExportExcel',
            icon:'experiment',
            component: './operation/centreLibrary/transferExport/CentreAllocationExcel',
            showBack:true,

          },
          {
            path: '/centre-library/centre-allocation/approveWarehoue',
            icon:'experiment',
            component: './operation/centreLibrary/ApproveWarehoue',
          },
          {
            path: '/centre-library/centre-allocation/refuseApproveWarehoue',
            icon:'experiment',
            component: './operation/centreLibrary/RefuseApproveWarehoue',
          },
          {
            name: 'centre-purchase',
            path: '/centre-library/centre-purchase',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-purchase',
                redirect:'/centre-library/centre-purchase/list',
              },
              {
                name: 'list',
                path: '/centre-library/centre-purchase/list',
                icon:'deployment-unit',
                component: './operation/centreLibrary/centrePurchase/MaterialPurchase',
              },
              {
                name: 'detail',
                path: '/centre-library/centre-purchase/detail',
                icon:'gateway',
                component: './operation/centreLibrary/centrePurchase/detail',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'save',
                path: '/centre-library/centre-purchase/save',
                icon:'gateway',
                component: './operation/centreLibrary/centrePurchase/Form/materialForm',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'saveSurplus',
                path: '/centre-library/centre-purchase/saveSurplus',
                icon:'gateway',
                component: './operation/centreLibrary/centrePurchase/Form/surplusForm',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'warehousing',
                path: '/centre-library/centre-purchase/warehousing',
                icon:'experiment',
                component: './operation/centreLibrary/centreScience/steelPlate/Form/Purchase',
                hideInMenu: true,
                showBack:true,
              },
            ]
          },
          {
            name: 'centre-stockInOut',
            path: '/centre-library/centre-stockInOut',
            icon:'experiment',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-stockInOut',
                redirect:'/centre-library/centre-stockInOut/list',
              },
              {
                name: 'list',
                path: '/centre-library/centre-stockInOut/list',
                icon:'experiment',
                component: './operation/centreLibrary/centreStockIn/InOutStock',
              },
              {
                name: 'stockIn',
                path: '/centre-library/centre-stockInOut/stockIn',
                icon:'experiment',
                component: './operation/centreLibrary/centreStockIn',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'stockOut',
                path: '/centre-library/centre-stockInOut/stockOut',
                icon:'experiment',
                component: './operation/centreLibrary/centreStockOut',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'check',
                icon: 'car',
                path: '/centre-library/centre-stockInOut/check',
                hideInMenu: true,
                showBack:true,
                component: './operation/centreLibrary/centreStockIn/StockTakingForm',
              },
              {
                name: 'noCheck',
                icon: 'car',
                path: '/centre-library/centre-stockInOut/noCheck',
                hideInMenu: true,
                showBack:true,
                component: './operation/centreLibrary/centreStockIn/NoStockTakingForm',
              },
            ]
          },
          {
            name: 'centre-stockInItem',
            path: '/centre-library/centre-stockInItem',
            icon:'experiment',
            component: './operation/centreLibrary/centreStockIn/Detail',
            hideInMenu: true,
            showBack:true,
          },
         /* {
            name: 'centre-notPurchaseStockIn',
            path: '/centre-library/centre-notPurchaseStockIn',
            icon:'experiment',
            component: './operation/centreLibrary/centreStockIn/component/NotPurchaseStockIn',
            hideInMenu: true,
            showBack:true,
          },*/
          {
            name: 'centre-stockOutItem',
            path: '/centre-library/centre-stockOutItem',
            icon:'experiment',
            component: './operation/centreLibrary/centreStockOut/detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'centre-StockOutOperation',
            path: '/centre-library/centre-StockOutOperation',
            icon:'experiment',
            component: './operation/centreLibrary/centreStockOut/component/StockOutOperation',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'centre-ReturnGoodsOperation',
            path: '/centre-library/centre-ReturnGoodsOperation',
            icon:'experiment',
            component: './operation/centreLibrary/centreStockOut/component/ReturnGoodsOperation',
            hideInMenu: true,
            showBack:true,
          },
          {
            name: 'centre-customer',
            path: '/centre-library/centre-customer',
            icon:'solution',
            routes: [
              {
                path: '/centre-library/centre-customer',
                redirect:'/centre-library/centre-customer/list',
              },
              {
                name: 'list',
                path: '/centre-library/centre-customer/list',
                icon:'solution',
                component: './operation/centreLibrary/centreCustomer/List',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'stock-in',
                path: '/centre-library/centre-customer/stock-in',
                icon:'solution',
                component: './operation/centreLibrary/centreCustomer/StockIn',
                hideInMenu: true,
                showBack:true,
              },
              {
                name: 'stock-out',
                path: '/centre-library/centre-customer/stock-out',
                icon:'solution',
                component: './operation/centreLibrary/centreCustomer/StockOut',
                hideInMenu: true,
                showBack:true,
              },
            ]
          },
          {
            name: 'steel-plate-split',
            path: '/centre-library/steel-plate-split',
            icon:'experiment',
            component: './operation/centreLibrary/SteelPlateSplit',
            hideInMenu: true,
            showBack:true,
          },
          //中心仓库存月报
          {
            name: 'centre-InventoryReport',
            path: '/centre-library/centre-InventoryReport',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-InventoryReport',
                redirect:'/centre-library/centre-InventoryReport/list',
              },
              {
                name: 'list',
                path: '/centre-library/centre-InventoryReport/list',
                icon:'experiment',
                component: './operation/centreLibrary/centreInventoryReport/index',
              }
            ]
          },
          {
            name: 'centre-centreMaterialStock',
            path: '/centre-library/centre-centreMaterialStock',
            icon:'deployment-unit',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/centre-library/centre-centreMaterialStock',
                redirect:'/centre-library/centre-centreMaterialStock/list',
              },
              {
                name: 'list',
                path: '/centre-library/centre-centreMaterialStock/list',
                icon:'experiment',
                component: './operation/centreLibrary/centreMaterialStock/index',
              }
            ]
          },
        ]
      },
      {
        name: 'basic-library',
        path: '/basic-library',
        icon: 'hdd',
        routes: [
          {
            name: 'material-texture',
            icon: 'switcher',
            path: '/basic-library/material-texture',
            component: './operation/basicLibrary/MaterialTexture',
          },
          {
            name: 'plate-thickness',
            icon: 'switcher',
            path: '/basic-library/plate-thickness',
            component: './operation/basicLibrary/PlateThickness',
          },
          {
            name: 'profile-material',
            icon: 'switcher',
            path: '/basic-library/profile-material',
            component: './operation/basicLibrary/profileMaterial',
          },
          {
            name: 'cut-method',
            icon: 'switcher',
            path: '/basic-library/cut-method',
            component: './operation/basicLibrary/CutMethod',
          },
          {
            name: 'cut-seam',
            icon: 'disconnect',
            path: '/basic-library/cut-seam',
            component: './operation/basicLibrary/cuttingSeam',
          },// 切割缝
          {
            name: 'contract-template',
            icon: 'file-protect',
            path: '/basic-library/contract-template',
            component: './operation/basicLibrary/contractTemplate/List',
          },
          {
            name: 'quality-book',
            icon: 'disconnect',
            path: '/basic-library/quality-book',
            component: './operation/basicLibrary/warrantyManagement',
          },// 质保书
          {
            name: 'work-order',
            icon: 'pull-request',
            path: '/basic-library/work-order',
            component: './operation/basicLibrary/WorkingProcedure',
          },// 工序
          {
            name: 'material-brand',
            icon: 'tags',
            path: '/basic-library/material-brand',
            component: './operation/basicLibrary/materialBrand',
          },
          {
            name: 'material-standard',
            icon: 'profile',
            path: '/basic-library/material-standard',
            component: './operation/basicLibrary/materialTypeStandard',
          },
          {
            name: 'material-category',
            icon: 'switcher',
            path: '/basic-library/material-category',
            component: './operation/basicLibrary/materialCategory',
          },
          {
            name: 'postSale_badType',
            icon: 'switcher',
            path: '/basic-library/postSale_badType',
            component: './operation/basicLibrary/postSaleBadType',
          },
          //3材质品牌
          // {
          //   name: 'material-type-list',
          //   icon: 'switcher',
          //   path: '/basic-library/material-type-list',
          //   component: './operation/basicLibrary/materialType',
          // },// 材质列表
          // {
          //   name: 'material-thickness-list',
          //   icon: 'save',
          //   path: '/basic-library/material-thickness-list',
          //   component: './operation/basicLibrary/materialThickness',
          // },
          // {
          //   name: 'cutting-method-list',
          //   icon: 'disconnect',
          //   path: '/basic-library/cutting-method-list',
          //   component: './operation/basicLibrary/cuttingMethod',
          // },// 切割工艺


          // {
          //   name: 'global-use-rate',
          //   icon: 'bars',
          //   path: '/basic-library/global-use-rate',
          //   component: './operation/basicLibrary/globalUseRate/List',
          // },
          // {
          //   name: 'system-taxRate',
          //   icon: 'tags',
          //   path: '/basic-library/system-taxRate',
          //   component: './operation/basicLibrary/systemTaxRate',
          // },
          // {
          //   path: '/basic/library/management/material-thickness-add',
          //   component: './operation/basicLibrary/materialThickness/AddMaterialThickness',
          // },

          // {
          //   name: 'equipment-brand-list',
          //   icon: 'trophy',
          //   path: '/basic-library/equipment-brand-list',
          //   component: './operation/basicLibrary/equipmentBrand',
          // },
          // {
          //   name: 'formula-define-list',
          //   icon: 'bar-chart',
          //   path: '/basic-library/formula-define-list',
          //   component: './operation/basicLibrary/formulaDefine',
          // },

        ]
      },
      {
        name: 'base-price',
        path: '/base-price',
        icon: 'dollar',
        component: './operation/basePrice/BasePriceManagement',
      },
      {
        name: 'base-price-save',
        icon: 'bars',
        path: '/base-price/base-price-save',
        component: './operation/basePrice/material/material/List/Excelint',
        hideInMenu: true,
        showBack:true,
      },
      {
        name: 'contract-template',
        icon: 'file-protect',
        path: '/contract-template',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/contract-template',
            redirect: '/contract-template/list',
          },
          {
            name: 'list',
            icon: 'UserOutlined',
            path: '/contract-template/list',
            component: './operation/contractTemplate/List'
          },
        ]
      },
      {
        name: 'weight',
        icon: 'heat-map',
        path: '/weight',
        // hideChildrenInMenu: true,
        routes: [
          {
            path: '/weight',
            redirect: '/weight/type',
          },
          {
            name: 'weight-type',
            path: '/weight/type',
            icon: 'heat-map',
            component: './operation/Weight',
          },
          {
            name: 'formula-define-list',
            icon: 'bar-chart',
            path: '/weight/formula-define-list',
            component: './operation/basicLibrary/formulaDefine',
          },
          {
            name: 'system-taxRate',
            icon: 'tags',
            path: '/weight/system-taxRate',
            component: './operation/basicLibrary/systemTaxRate',
          },
          {
            name: 'globalSetting',
            icon: 'history',
            path: '/weight/globalSetting',
            component: './operation/basicLibrary/globalSetting',
          },
          {
            name: 'global-use-rate',
            icon: 'bars',
            path: '/weight/global-use-rate',
            component: './operation/basicLibrary/globalUseRate/List',
          },
          {
            name: 'global-use-rate-save',
            icon: 'bars',
            path: '/weight/global-use-rate-save',
            component: './operation/basicLibrary/globalUseRate/List/Excelint',
            hideInMenu: true,
            showBack:true,
          },
        ]
      },
      {
        name: 'statistics',
        icon: 'laptop',
        path: '/statistics',
        routes: [
          {
            path: '/statistics',
            redirect: '/statistics/list',
          },
          {
            name: 'list',
            icon: 'solution',
            path: '/statistics/list',
            component: './operation/statistics/Statistics',
            hideInMenu: true,
            showBack:true,
          },
        ],
      },
      {
        name: 'system',
        icon: 'laptop',
        path: '/system',
        routes: [
          {
            name: 'user',
            icon: 'user',
            path: '/system/user',
            component: './operation/system/user/List',
          },
          {
            name: 'role',
            icon: 'solution',
            path: '/system/role',
            component: './operation/system/role/List',
          },
          //微信消息模板路由
          {
            name: 'weChatTemplate',
            icon: 'solution',
            path: '/system/weChatTemplate',
            component: './operation/system/wechatTemplate/List',
          },
          //阿米巴部门配置模板
          {
            name: 'departmentTemplate',
            icon: 'solution',
            path: '/system/departmentTemplate',
            component: './operation/system/departmentTemplate/List',
          },
          {
            name: 'function',
            icon: 'deployment-unit',
            path: '/system/function',
            component: './operation/system/function/List',
          },
          {
            name: 'interface',
            icon: 'api',
            path: '/system/interface',
            component: './operation/system/interface/List',
          },
          {
            name: 'announcement',
            icon: 'api',
            path: '/system/announcement',
            component: './operation/system/announcement/Index',
          },
          {
            name: 'statistics',
            icon: 'api',
            path: '/system/statistics',
            component: './operation/system/statistics/List',
          },
          {
            name: 'dictionary',
            icon: 'api',
            path: '/system/dictionary',
            component: './operation/system/dictionary/List',
          },
          {
            name: 'weight',
            icon: 'SettingOutlined',
            path: '/system/weight',
          },
        ],
      },
      {
        name: 'setting',
        icon: 'setting',
        path: '/setting',
        routes: [
          {
            path: '/setting',
            redirect: '/setting/profile',
          },
          {
            name: 'profile',
            path: '/setting/profile',
            icon: 'UserOutlined',
            component: './operation/setting/Profile',
          },
        ]
      }
    ],
  },
];
